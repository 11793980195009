import { useEffect, useRef, useState } from "react"
import { useSanityApi } from "../../../website-integration/hooks/sanityApi.hook"
import "./InternalDocuments.scss"
import { SkeletonWrapper } from "../../../common/components/SkeletonWrapper/SkeletonWrapper"
import DocumentCard from "../../../common/components/cards/document-card/DocumentCard"
import Hero from "../../../common/components/hero/Hero"
import { useSearchParams } from "react-router-dom"
import Pagination from "../../../common/components/pagination/pagination"
import MultiOptionFilter from "../../../common/components/filters/multi-options-filter"
import Button from "../../../common/components/button/Button"
import Filter from "../../../common/assets/img/icons/filter.svg"
import { applyFiltersAndPaginate } from "../../../website-integration/util/documents_filters_helpers"
import ImageText from "../../../common/components/image-text/ImageText"
import { useTranslation } from "react-i18next"
import SortByButton from "../../../common/components/SortByButton/SortByButton"

interface FilterComponent {
    className: string
    color: string
    onChange?: (values: string[]) => void
    filter_category_name: string
    activeValues: string[]
    initSize: number
}

export const InternalDocuments = () => {
    const sanityApi = useSanityApi()
    const { t } = useTranslation()

    const handleUrlSeachParamsInState = (searchParamsState: string[]) => {
        if (searchParamsState.length > 0 && searchParamsState[0].includes("_")) {
            return searchParamsState[0].split("_")
        } else {
            return searchParamsState
        }
    }

    
    const handleSearchParamsForAppl = (selectedApplicables: string[]) => {
        const normalizedApplicables: string[] = selectedApplicables.length > 0 && selectedApplicables[0].includes("_") ? selectedApplicables[0].split("_") : selectedApplicables
        const mappedApplicables: string[] = []
        applicableOptions.map((item) => {
            if (normalizedApplicables.includes(item.key.toLowerCase())) {
                mappedApplicables.push(item.key)
            }
        })
        
        return mappedApplicables
    }
    
    const applicableOptions = [
        {
            key: "pcgInternational",
            value: "PCG International",
            label: "PCG International",
        },
        {
            key: "pcgAustria",
            value: "PCG Austria",
            label: "PCG Austria",
        },
        {
            key: "pcgGermany",
            value: "PCG Germany",
            label: "PCG Germany",
        },
    ]
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showFilters, setShowFilters] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const [page, setPage] = useState<number>(parseInt(searchParams.get("page") || "1", 10))

    let searchParamsTypes = searchParams.getAll("types")
    let searchParamsDep = searchParams.getAll("departments")
    let searchParamsAppl = searchParams.getAll("applicables")

    const [selectedTypes, setSelectedTypes] = useState<string[]>(handleUrlSeachParamsInState(searchParamsTypes))

    const [selectedDepartments, setSelectedDepartments] = useState<string[]>(handleUrlSeachParamsInState(searchParamsDep))
    const [selectedApplicables, setselectedApplicables] = useState<any>(handleSearchParamsForAppl(searchParamsAppl))

    const [filteredDocuments, setFilteredDocuments] = useState<any[]>([])
    const [totalDocumentsCount, setTotalDocumentsCount] = useState<any>(null)

    const [selectedSort, setSelectedSort] = useState<string>(searchParams.get("order") ?? "Descending")
    const [selectedItem, setSelectedItem] = useState<string>(searchParams.get("sort") ?? "Date Updated")

    const dropdownRef = useRef<any>(null)

    const OnPageChange = (newPage: number) => {
        setPage(newPage)
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters)
    }

    let typesFilterComponent: FilterComponent = {
        filter_category_name: "Type",
        className: "insights--industries",
        color: "clr-tertiary-500",
        activeValues: selectedTypes,
        initSize: 15,
    }
    let departmentsFilterComponent: FilterComponent = {
        filter_category_name: "Department",
        className: "insights--languages",
        color: "clr-tertiary-500",
        activeValues: selectedDepartments,
        initSize: 15,
    }
    let applicablesFilterComponent: FilterComponent = {
        filter_category_name: "Applicable For",
        className: "insights--countries",
        color: "clr-tertiary-500",
        activeValues: selectedApplicables,
        initSize: 15,
    }

    const resetAllFilters = () => {
        searchParams.delete("types")
        searchParams.delete("departments")
        searchParams.delete("applicables")
        setSearchParams(searchParams)

        setSelectedTypes([])
        setSelectedDepartments([])
        setselectedApplicables([])
    }

    const resetTypes = () => {
        searchParams.delete("types")
        setSelectedTypes([])
    }
    const resetDepartments = () => {
        searchParams.delete("departments")
        setSelectedDepartments([])
    }

    const resetApplicables = () => {
        searchParams.delete("applicables")
        setselectedApplicables([])
    }

    const setAll = (key: string, params: string[]) => {
        searchParams.delete(key)
        searchParams.append(key, params.map((item) => item.toLowerCase()).join("_"))
        setSearchParams(searchParams)
    }

    const handleFilterChange = (filterType: string, selectedValues: string[]) => {
        switch (filterType) {
            case "types":
                setSelectedTypes(selectedValues)
                setAll("types", selectedValues)
                break
            case "departments":
                setSelectedDepartments(selectedValues)
                setAll("departments", selectedValues)

                break
            case "applicables":
                setselectedApplicables(selectedValues)
                setAll("applicables", selectedValues)

                break
            default:
                break
        }
    }

    const typeOptions = [
        { value: "Policy", key: "policy", label: "Policy" },
        { value: "Process", key: "process", label: "Process" },
        { value: "Guide", key: "guide", label: "Guide" },
        { value: "Checklist", key: "checklist", label: "Checklist" },
        { value: "Template", key: "template", label: "Template" },
        { value: "Database", key: "database", label: "Database" },
        { value: "KPIs", key: "kpis", label: "KPIs" },
        { value: "Workbook", key: "workbook", label: "Workbook" },
        { value: "Presentation", key: "presentation", label: "Presentation" },
        { value: "Knowledge Base", key: "knowledgebase", label: "Knowledge Base" },
    ]

    const renderFilters = () => (
        <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="5vh">
            <MultiOptionFilter className={typesFilterComponent.className} color={typesFilterComponent.color} options={typeOptions} onChange={(selectedValues) => handleFilterChange("types", selectedValues)} filter_category_name={typesFilterComponent.filter_category_name} activeValues={selectedTypes} filterType={"types"} resetAllFilters={resetTypes} />
            <MultiOptionFilter className={departmentsFilterComponent.className} color={departmentsFilterComponent.color} options={sanityApi.departments} onChange={(selectedValues) => handleFilterChange("departments", selectedValues)} filter_category_name={departmentsFilterComponent.filter_category_name} activeValues={selectedDepartments} filterType={"departments"} resetAllFilters={resetDepartments} />
            <MultiOptionFilter className={applicablesFilterComponent.className} color={applicablesFilterComponent.color} options={applicableOptions} onChange={(selectedValues) => handleFilterChange("applicables", selectedValues)} filter_category_name={applicablesFilterComponent.filter_category_name} activeValues={selectedApplicables} filterType={"applicables"} resetAllFilters={resetApplicables} />
            {(selectedTypes.length > 0 || selectedDepartments.length > 0 || selectedApplicables.length > 0) && (
                <Button
                    className="btn__icon--circle__insights--clear_filters btn-close"
                    color={"white"}
                    onClick={() => {
                        resetAllFilters()
                    }}
                />
            )}
            <div className="sort-button-container">
                <SortByButton selectedItem={selectedItem} selectedSort={selectedSort} selectedAscDesc={(item) => setSelectedSort(item)} selectedItemChange={(item) => setSelectedItem(item)} name="Sort By" ascDescData={["Ascending", "Descending"]} mainData={["Date Updated", "Date Created", "Title", "Department"]} />
            </div>
        </SkeletonWrapper>
    )

    const renderFiltersDropdown = () => (
        <div className="documents-filters-dropdown" ref={dropdownRef}>
            <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="5vh">
                <div className="documents-filters-dropdown__inner">
                    <MultiOptionFilter className={typesFilterComponent.className} color={typesFilterComponent.color} options={typeOptions} onChange={(selectedValues) => handleFilterChange("types", selectedValues)} filter_category_name={typesFilterComponent.filter_category_name} activeValues={selectedTypes} filterType={"types"} resetAllFilters={resetTypes} />
                    <MultiOptionFilter className={departmentsFilterComponent.className} color={departmentsFilterComponent.color} options={sanityApi.departments} onChange={(selectedValues) => handleFilterChange("departments", selectedValues)} filter_category_name={departmentsFilterComponent.filter_category_name} activeValues={selectedDepartments} filterType={"departments"} resetAllFilters={resetDepartments} />
                    <MultiOptionFilter className={applicablesFilterComponent.className} color={applicablesFilterComponent.color} options={applicableOptions} onChange={(selectedValues) => handleFilterChange("applicables", selectedValues)} filter_category_name={applicablesFilterComponent.filter_category_name} activeValues={selectedApplicables} filterType={"applicables"} resetAllFilters={resetApplicables} />
                    {(selectedTypes.length > 0 || selectedDepartments.length > 0 || selectedApplicables.length > 0) && (
                        <div className="multi-filters">
                            <Button
                                className="btn__filter__insights"
                                text={"Reset all"}
                                onClick={() => {
                                    resetAllFilters()
                                }}
                            ></Button>
                        </div>
                    )}
                </div>
            </SkeletonWrapper>
        </div>
    )

    useEffect(() => {
        if (sanityApi.documents) {
            const { totalCount, updatedDocuments } = applyFiltersAndPaginate(sanityApi.documents, selectedTypes, selectedDepartments, sanityApi.departments, selectedApplicables, page, 8, selectedSort, selectedItem)
            setFilteredDocuments(updatedDocuments)
            setTotalDocumentsCount(totalCount)
        }
    }, [sanityApi.documents, selectedTypes, selectedDepartments, selectedApplicables, page, sanityApi.departments, selectedSort, selectedItem])

    useEffect(() => {
        sanityApi.getInternalDocuments()
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(searchParams)

        selectedItem ? params.set("sort", selectedItem.toLowerCase().replace(/\s/g, "")) : params.delete("sort")
        selectedSort ? params.set("order", selectedSort.toLowerCase().replace(/\s/g, "")) : params.delete("order")

        setSearchParams(params)
    }, [selectedItem, selectedSort])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowFilters(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    let breadcrumbs = [
        {
            title: "Home",
            link: "/",
        },
        {
            title: "Documents",
            link: "/documents",
        },
    ]

    let allSelectedFilters = selectedTypes + "," + selectedDepartments + "," + selectedApplicables

    let allSelectedFiltersArray = allSelectedFilters.split(",").filter(Boolean)

    return (
        <div className="main-page-wrapper">
            <Hero title="Documents" breadcrumbs={breadcrumbs} />
            <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="50vh">
                <section className="columns-search-box on-documents">
                    {windowWidth < 1024 ? (
                        <div>
                            <div className="button-row">
                                <button className={`btn-filters ${showFilters ? "btn-filters-active" : ""}`} onClick={toggleFilters}>
                                    <img src={Filter} className="filter-icon" alt="Scroll to top" />
                                    <span className="filters-text">Filters</span>
                                    {allSelectedFiltersArray.length > 0 && (
                                        <span className="number-span">
                                            (<span className="number">{allSelectedFiltersArray.length}</span>)
                                        </span>
                                    )}
                                </button>
                                <SortByButton selectedItem={selectedItem} selectedSort={selectedSort} selectedAscDesc={(item) => setSelectedSort(item)} selectedItemChange={(item) => setSelectedItem(item)} name="Sort By" ascDescData={["Ascending", "Descending"]} mainData={["Date Updated", "Date Created", "Title", "Department"]} />
                            </div>
                            {showFilters && renderFiltersDropdown()}
                        </div>
                    ) : (
                        renderFilters()
                    )}
                </section>
                {filteredDocuments.length > 0 ? (
                    <section className="custom-grid">
                        {filteredDocuments.map((document: any, i: number) => (
                            <DocumentCard key={i} document={document} setSelectedTypes={setSelectedTypes} searchParams={searchParams} setSearchParams={setSearchParams} />
                        ))}
                    </section>
                ) : (
                    <ImageText title={t("serviceCards.noResults.title")} description={t("serviceCards.noResults.description")} />
                )}

                <Pagination totalItems={totalDocumentsCount} pageSize={8} OnPageChange={OnPageChange} currentPage={page} />
            </SkeletonWrapper>
        </div>
    )
}
