import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import moment from "moment"
import { useSanityApi } from "../../../website-integration/hooks/sanityApi.hook"
import { SkeletonWrapper } from "../../../common/components/SkeletonWrapper/SkeletonWrapper"
import { RichText } from "../../../common/components/richtext/RichText"
import { VersionHistorySection } from "../../../website-integration/components/sections/version-history/version-history"
import Chips from "../../../common/components/chips/Chips"
import HeadingTag from "../../../common/components/HeadingTag/HeadingTag"
import { TableOfContents } from "../../../website-integration/components/table-of-contents/table-of-contents"
import { SectionDocumentAuthors } from "../../../components/sections/internal-document-authors/SectionDocumentAuthors"
import "./InternalDocumentPage.scss"
import React from "react"
import { useTranslation } from "react-i18next"

const documentTypes: any = {
    P: "Policy",
    Pr: "Process",
    G: "Guide",
    C: "Checklist",
    T: "Template",
    DB: "Database",
    KPIs: "KPIs",
    Wb: "Workbook",
    Pt: "Presentation",
    KB: "Knowledge Base",
}

export const InternalDocumentPage = () => {
    const { t } = useTranslation()
    const sanityApi = useSanityApi()
    const [document, setDocument] = useState<any>()
    const [applicableFor, setApplicableFor] = useState<Array<string>>([])
    const [viewingRights, setViewingRights] = useState<any>("")
    const [isMobile, setIsMobile] = useState(false)
    const { pathname, state } = useLocation()
    const originalID = state?.originalID

    const handleMobile = () => {
        setIsMobile(window.innerWidth < 1024)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    useEffect(() => {
        handleMobile()
        window.addEventListener("resize", handleMobile)
        return () => {
            window.removeEventListener("resize", handleMobile)
        }
    }, [])

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                if (originalID) {
                    const fetchedDocument = await sanityApi.getInternalDocumentById(originalID)
                    setDocument(fetchedDocument)
                }
            } catch (error) {
                console.error("Error fetching document: ", error)
            }
        }

        fetchDocument()
    }, [originalID])

    useEffect(() => {
        if (document) {
            const { applicableFor, viewingRights } = document
            let arr = []
            for (const [key, value] of Object.entries(applicableFor)) {
                if (key.includes("_")) {
                    continue
                }
                if (value) {
                    arr.push("PCG ".concat(key.replace("pcg", "")))
                }
            }
            setApplicableFor(arr)

            setViewingRights(viewingRights)
        }
    }, [document])

    return (
        <div className="document-container">
            <SkeletonWrapper isLoading={sanityApi.loading} width="100%" height="50vh">
                {document ? (
                    <>
                        <section className="int-hero">
                            <div className="int-hero-chips">
                                <Chips text={documentTypes[document.documentType]} color="#ffea24" />
                                <Chips text={document.classification} color="white" />
                            </div>
                            <div className="int-hero-content">
                                <div className="int-hero-content-meta">
                                    <div>{document.documentId.current}</div>
                                    <span>&#8226;</span>
                                    <div>
                                        {t("internalDocuments.page.version")}
                                        {document.listVersionHistory && document.listVersionHistory.length > 0 ? document.listVersionHistory[document.listVersionHistory.length - 1]?.version.toString().padStart(3, "0") : "000"}
                                    </div>
                                    <span>&#8226;</span>
                                    <div>{moment(document._createdAt).format("DD MMM YYYY")}</div>
                                    <span>&#8226;</span>
                                    <div>
                                        {t("internalDocuments.page.last-modified")}
                                        {moment(document._updatedAt).format("DD MMM YYYY")}
                                    </div>
                                </div>
                                <div className="int-hero-content-title">
                                    <HeadingTag type="h3">{document.title}</HeadingTag>
                                </div>
                                <div className="int-hero-content-text">
                                    <p>{document.purpose}</p>
                                </div>
                            </div>
                            <div className="int-hero-applicable-for">
                                <div className="applicable">{t("internalDocuments.page.applicable-for")}</div>
                                <div className="units">
                                    {applicableFor.map((unit) => {
                                        return <Chips text={unit} color="white" />
                                    })}
                                    {viewingRights == "teams" && document.teamsWithViewingRights > 0 ? (
                                        document.teamsWithViewingRights.map((team: any) => {
                                            return <Chips text={team.name} color="white" />
                                        })
                                    ) : viewingRights == "owner" && document.owner ? (
                                        <Chips text={document.owner.name} color="white" />
                                    ) : (
                                        <Chips text={viewingRights} color="white" />
                                    )}
                                </div>
                            </div>
                        </section>
                        <section className="document-content">
                            <div className="document-content__text-container">
                                <RichText item={document.content?.["en"]} color="" className="" />
                            </div>
                            <div className="document-content__side-section">
                                <TableOfContents content={document.content["en"]} desktop={!isMobile} />
                            </div>
                        </section>
                        {document?.authors && <SectionDocumentAuthors authors={document?.authors} />}
                        <VersionHistorySection document={document} />
                    </>
                ) : (
                    <h2>{t("internalDocuments.page.not-found", { id: originalID })}</h2>
                )}
            </SkeletonWrapper>
        </div>
    )
}
