import App from "./App"
import { HomePage } from "./homePage/HomePage"
import { AdminDashboard } from "./admin/components/dashboard/AdminDashboard"
import { SalesPeople } from "./admin/components/salespeople/SalesPeople"
import { AdminLayout } from "./admin/layout/AdminLayout"
import { Login } from "./auth/login/Login"
import { CaseStudies } from "./website-integration/components/caseStudies/CaseStudies"
import { InternalDocumentPage } from "./documents/components/internalDocumentPage/InternalDocumentPage"
import { InternalDocumentsLayout } from "./documents/components/InternalDocumentsLayout/InternalDocumentsLayout"
import { InternalDocuments } from "./documents/components/InternalDocuments/InternalDocuments"
import { PageNotFoundComponent } from "./components/404/404"
import ProtectedRoute from "./protectedRoute"

export const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <ProtectedRoute element={<HomePage />} redirectTo="/login" />,
            },
            {
                path: "/case-studies/",
                element: <ProtectedRoute element={<CaseStudies />} redirectTo="/login" />,
            },
            {
                path: "/admin/",
                element: <ProtectedRoute element={<AdminLayout />} redirectTo="/login" />,
                children: [
                    {
                        path: "/admin/",
                        element: <ProtectedRoute element={<AdminDashboard />} redirectTo="/login" />,
                    },
                    {
                        path: "/admin/salespeople/",
                        element: <ProtectedRoute element={<SalesPeople />} redirectTo="/login" />,
                    },
                ],
            },
            {
                path: "/documents/",
                element: <ProtectedRoute element={<InternalDocumentsLayout />} redirectTo="/login" />,
                children: [
                    {
                        path: "/documents/",
                        element: <ProtectedRoute element={<InternalDocuments />} redirectTo="/login" />,
                    },
                    {
                        path: "/documents/:id/",
                        element: <ProtectedRoute element={<InternalDocumentPage />} redirectTo="/login" />,
                    },
                ],
            },
            {
                path: "*",
                element: <PageNotFoundComponent />,
            },
        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
]
