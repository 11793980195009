import { typeFullToShort } from "../../common/services/util-functions"
import Document from "../../models/Document"

export const filterByType = (documents: Document[], selectedTypes: any[]) => {
    if (selectedTypes.length > 0) {
        return documents.filter((document: any) => selectedTypes.some((selectedType) => typeFullToShort(selectedType) === document.documentType))
    }
    return documents
}

export const sortByTitle = (documents: Document[], isAscDesc: string | null): Document[] => {
    if (isAscDesc?.toLowerCase() === "ascending") {
        documents.sort((a, b) => a.title.localeCompare(b.title))
    } else {
        documents.sort((a, b) => b.title.localeCompare(a.title))
    }

    const featuredDocs = documents.filter((doc) => doc.featured === true)
    const nonFeaturedDocs = documents.filter((doc) => doc.featured !== true)

    return [...featuredDocs, ...nonFeaturedDocs]
}

const parseDocId = (doc: Document) => {
    let id = doc.documentId.current

    const parts = id.split("-")

    return [parts[1], parts[2], parts[3]]
}

export const sortByDepartment = (documents: Document[], isAscDesc: string | null): Document[] => {
    if (isAscDesc?.toLowerCase() === "ascending") {
        documents.sort((a, b) => {
            const [departA, docTypeA, docNumberA] = parseDocId(a)
            const [departB, docTypeB, docNumberB] = parseDocId(b)

            const depart = departA.localeCompare(departB)
            if (depart !== 0) return depart

            const docType = docTypeA.localeCompare(docTypeB)
            if (docType !== 0) return docType

            return parseInt(docNumberA) - parseInt(docNumberB)
        })
    } else {
        documents.sort((a, b) => {
            const [departA, docTypeA, docNumberA] = parseDocId(a)
            const [departB, docTypeB, docNumberB] = parseDocId(b)

            const depart = departB.localeCompare(departA)
            if (depart !== 0) return depart

            const docType = docTypeB.localeCompare(docTypeA)
            if (docType !== 0) return docType

            return parseInt(docNumberB) - parseInt(docNumberA)
        })
    }

    const featuredDocs = documents.filter((doc) => doc.featured === true)
    const nonFeaturedDocs = documents.filter((doc) => doc.featured !== true)

    return [...featuredDocs, ...nonFeaturedDocs]
}

export const sortByCreatedDate = (documents: Document[], isAscDesc: string | null): Document[] => {
    if (isAscDesc?.toLowerCase() === "ascending") {
        documents.sort((a: Document, b: Document) => {
            return new Date(a.listVersionHistory[0].date).getTime() - new Date(b.listVersionHistory[0].date).getTime()
        })
    } else {
        documents.sort((a: Document, b: Document) => {
            return new Date(b.listVersionHistory[0].date).getTime() - new Date(a.listVersionHistory[0].date).getTime()
        })
    }

    const featuredDocs = documents.filter((doc) => doc.featured === true)
    const nonFeaturedDocs = documents.filter((doc) => doc.featured !== true)

    return [...featuredDocs, ...nonFeaturedDocs]
}

export const sortByDateUpdated = (documents: Document[], isAscDesc: string | null): Document[] => {
    if (isAscDesc?.toLowerCase() === "ascending") {
        documents.sort((a: Document, b: Document) => {
            return new Date(a.latestVersionDate).getTime() - new Date(b.latestVersionDate).getTime()
        })
    } else {
        documents.sort((a: Document, b: Document) => {
            return new Date(b.latestVersionDate).getTime() - new Date(a.latestVersionDate).getTime()
        })
    }

    const featuredDocs = documents.filter((doc) => doc.featured === true)
    const nonFeaturedDocs = documents.filter((doc) => doc.featured !== true)

    return [...featuredDocs, ...nonFeaturedDocs]
}

export const filterByDepartment = (documents: Document[], selectedDepartments: any[], allDepartments: any[]) => {
    const departmentAbbreviations = new Set(allDepartments.map((department) => department.abbreviation))

    if (selectedDepartments.length > 0) {
        return documents.filter((document: any) => {
            const { global, viewingRights, owner, teamsWithViewingRights } = document

            return selectedDepartments.some((selectedDepartment) => {
                if (!departmentAbbreviations.has(selectedDepartment)) {
                    return false
                }

                if (global) {
                    return true
                } else if (viewingRights === "owner" || viewingRights === "public") {
                    return selectedDepartment === owner?.abbreviation
                } else if (viewingRights === "teams") {
                    return teamsWithViewingRights && teamsWithViewingRights.some((team: any) => team.abbreviation === selectedDepartment)
                }

                return false
            })
        })
    }

    return documents
}

export const filterByApplicable = (documents: Document[], selectedApplicables: any[]) => {
    const applicableOptions = [
        {
            key: "pcgInternational",
            value: "PCG International",
            label: "PCG International",
        },
        {
            key: "pcgAustria",
            value: "PCG Austria",
            label: "PCG Austria",
        },
        {
            key: "pcgGermany",
            value: "PCG Germany",
            label: "PCG Germany",
        },
    ]

    const normalizedApplicables: string[] = selectedApplicables.length > 0 && selectedApplicables[0].includes("_") ? selectedApplicables[0].split("_") : selectedApplicables

    const mappedApplicables: string[] = selectedApplicables.length > 0 && selectedApplicables[0].includes("_")
        ? normalizedApplicables.map((applicable) => {
              const match = applicableOptions.find((option) => option.key.toLowerCase() === applicable)
              return match ? match.key : applicable
          })
        : selectedApplicables

    if (mappedApplicables.length > 0) {
        return documents.filter((document: any) => {
            return mappedApplicables.some((selectedApplicable) => {
                return document.applicableFor && document.applicableFor[selectedApplicable] === true
            })
        })
    }
    return documents
}

export const applyFiltersAndPaginate = (documents: Document[], selectedTypes: any[], selectedDepartments: any[], allDepartments: any[], selectedApplicables: any[], page: number, pageSize: number = 8, isAscDesc: string | null, selectedItem: string | null) => {
    let filteredDocuments = filterByType(documents, selectedTypes)
    filteredDocuments = filterByDepartment(filteredDocuments, selectedDepartments, allDepartments)
    filteredDocuments = filterByApplicable(filteredDocuments, selectedApplicables)

    const normalizedSelectedItem = selectedItem ? selectedItem.toLowerCase().replace(/\s/g, "") : ""

    let sortedDocuments: Document[] = [...filteredDocuments]

    switch (normalizedSelectedItem) {
        case "title":
            sortedDocuments = sortByTitle(sortedDocuments, isAscDesc)
            break
        case "dateupdated":
            sortedDocuments = sortByDateUpdated(sortedDocuments, isAscDesc)
            break
        case "datecreated":
            sortedDocuments = sortByCreatedDate(sortedDocuments, isAscDesc)
            break
        case "department":
            sortedDocuments = sortByDepartment(sortedDocuments, isAscDesc)
            break
        default:
            break
    }

    const totalCount = sortedDocuments.length
    const startIndex = (page - 1) * pageSize
    const endIndex = startIndex + pageSize
    const updatedDocuments = sortedDocuments.slice(startIndex, endIndex)

    return { totalCount, updatedDocuments }
}
