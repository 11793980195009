import React, { useRef } from "react"
import "./DocumentCard.scss"
import ellipse from "../../../assets/img/icons/ellipse.svg"
import Tag from "../../tag/Tag"
import moment from "moment"
import FeaturedTag from "../../featuredTag/FeaturedTag"
import { Link } from "react-router-dom"
import { typeShortToFull } from "../../../services/util-functions"
import cardNew from "../../../assets/img/icons/card-new.svg"
import cardUpdated from "../../../assets/img/icons/card-updated.svg"

export default function DocumentCard({ document, setSelectedTypes, searchParams, setSearchParams }: { document: any; setSelectedTypes: any; searchParams: any; setSearchParams: any }) {
    const clickFilterType = () => {
        const type = typeShortToFull(document.documentType)
        setSelectedTypes([type])
        searchParams.set("types", type)
        setSearchParams(searchParams)
    }

    return (
        <div className={`card ${document.featured ? "featured" : ""}`}>
            {document.documentType && (
                <div>
                    <span className={`card__type ${shortToFull(document.documentType)?.toLowerCase()}`} onClick={clickFilterType}>
                        {shortToFull(document.documentType)}
                    </span>
                </div>
            )}
            <div className={`card__content ${shortToFull(document.documentType)?.toLowerCase()}`}>
                <div className="card__content__wrapper">
                    <div className="card__content__metadata">
                        {document?.documentId?.current && <div className="card__content__metadata__id">{document.documentId.current}</div>}
                        {document.listVersionHistory && (
                            <>
                                {<img className="card__content__metadata__dot" src={ellipse} alt="dot" />}
                                {<div className="card__content__metadata__date">{moment(document.listVersionHistory[0].date).format("DD MMM YYYY")}</div>}
                                {<img className="card__content__metadata__dot" src={ellipse} alt="dot" />}
                                {<div className="card__content__metadata__modified">Last modified: {moment(document.listVersionHistory[document.listVersionHistory.length - 1].date).format("DD MMM YYYY")}</div>}
                            </>
                        )}
                    </div>
                    <div className="card__content__main">
                        <h5 className="card__content__main__title">{document.title}</h5>
                        {document.purpose && <p className="card__content__main__description">{document.purpose}</p>}
                    </div>
                    <div className="card__content__tags">
                        {document?.classification && <Tag title={capitalizeFirstLetter(document?.classification)} featured={document.featured}></Tag>}
                        {document?.viewingRights === "teams" && document?.teamsWithViewingRights?.length > 0 && (
                            <>
                                {document.teamsWithViewingRights.map((team: any, i: any) => (
                                    <Tag key={i} title={team.name} featured={document.featured} />
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {document.featured && (
                <div className="card__featuredTag">
                    <FeaturedTag type={document.documentType} />
                </div>
            )}
            <Link to={document?.documentId?.current.toLowerCase() + "/"} state={{originalID: document?.documentId?.current}} className="card__link" />

            {document.isNew && !document.featured && (
                <div className="badge-new">
                    <img src={cardNew} alt="Icon" />
                </div>
            )}

            {document.isFreshlyUpdated && !document.featured && (
                <div className="badge-updated">
                    <img src={cardUpdated} alt="Icon" />
                </div>
            )}
        </div>
    )
}

type TypesMapKeys = keyof typeof typesMap

const typesMap = {
    P: "Policy",
    Pr: "Process",
    G: "Guide",
    C: "Checklist",
    T: "Template",
    DB: "Database",
    KPIs: "KPIs",
    Wb: "Workbook",
    Pt: "Presentation",
    KB: "Knowledge Base",
} as const

function shortToFull(type: TypesMapKeys): string {
    return typesMap[type]
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
