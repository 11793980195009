import { useEffect, useRef, useState } from "react"
import "./SortByButton.scss"
import SortArrows from "../../assets/img/icons/sort-arrows.svg"
import SortAscending from "../../assets/img/icons/sort-asc.svg"
import SortDescending from "../../assets/img/icons/sort-desc.svg"

interface SortByProps {
    name: string
    ascDescData: string[]
    mainData: string[]
    selectedSort: string
    selectedItem: string
    selectedAscDesc: (item: string) => void
    selectedItemChange: (item: string) => void
}

const SortByButton = ({ name, ascDescData, mainData, selectedSort, selectedItem, selectedAscDesc, selectedItemChange }: SortByProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const sortMappings: { [key: string]: string } = {
        dateupdated: "Date Updated",
        datecreated: "Date Created",
        title: "Title",
        department: "Department",
        ascending: "Ascending",
        descending: "Descending",
    }

    const toggleDropdown = () => {
        setIsOpen((open) => !open)
    }

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div className="dropdown" ref={ref}>
            <button className={`button-sort ${isOpen ? "selected" : ""}`} onClick={toggleDropdown}>
                <img src={SortArrows} />
                {name}
            </button>
            {isOpen && (
                <div className="dropdown-content">
                    <div className="dropdown-items">
                        {ascDescData.map((item, i) => (
                            <button key={i} className={`dropdown-item ${selectedSort === item || sortMappings[selectedSort] === item ? "selected" : ""}`} onClick={() => selectedAscDesc(item)}>
                                <img src={item === "Ascending" ? SortAscending : SortDescending} />
                                {item}
                            </button>
                        ))}
                    </div>
                    <div>
                        {mainData.map((item, i) => (
                            <p key={i} className={`dropdown-item-list ${selectedItem === item || sortMappings[selectedItem] === item ? "selected" : ""}`} onClick={() => selectedItemChange(item)}>
                                {item}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SortByButton
